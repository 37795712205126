import React, {Fragment} from 'react';
import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js';
import './homepage.css'

export default class MapContainer extends React.Component {

  constructor(props) {
    super(props);
    // the reference to the container
    this.ref = React.createRef()
    // reference to the map
    this.map = null;
    this.ui = null;
    this.sharedSiteMarker = null;
    this.markers = null;
    this.platform = null;

     const defaultCenter = {
        lat: 43.038902, lng: -87.906471,
      }

    const defaultZoom = 14;

    this.state = {
        location: JSON.parse(JSON.stringify(defaultCenter)),
        defaultCenter, defaultZoom,
        position: defaultCenter,
        zoom: defaultZoom,

        mapInstance: null,

        mapPinIcon: new H.map.Icon('<svg height="19" width="19" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="7" stroke="white" fill="#1b468d" /></svg> '),

        groupSitesMarkerIcon: new H.map.Icon('<svg width="24" height="24" ' +
          'xmlns="http://www.w3.org/2000/svg">' +
          '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
          'height="22" /><text x="12" y="18" font-size="12pt" ' +
          'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
          'fill="white">G</text></svg>'),

        regularSiteMarkerIcon: new H.map.Icon('<svg width="24" height="24" ' +
          'xmlns="http://www.w3.org/2000/svg">' +
          '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
          'height="22" /><text x="12" y="18" font-size="12pt" ' +
          'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
          'fill="white"></text></svg>'),

        sharedSiteMarkerIcon: new H.map.Icon('<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">'+
        '<rect stroke="white" fill="#025e25" x="18" y="-12" width="22" height="22" transform="rotate(45)"/></svg>'),
    }
  }

  componentDidMount() {
    //console.log("Ghostly Overhang Debug", "calling componentDidMount", "==========================================================================");
    let {defaultZoom, defaultCenter} = this.state;
    if (!this.ref){
      this.ref = React.createRef();
    }

    if (!this.map) {
      // instantiate a platform, default layers and a map as usual
      const platform = new H.service.Platform({
        apikey: process.env.REACT_APP_HERE_API_KEY
      });

      //console.log("H.service:", H.service);
      const engineType = H.Map.EngineType['HARP'];

      const layers = platform.createDefaultLayers({ engineType });
      const map = new H.Map(
        this.ref.current,
        layers.raster.satellite.map,
        {
          engineType,
          pixelRatio: window.devicePixelRatio,
          center: defaultCenter,
          zoom: defaultZoom
        },
      );

      const ui = H.ui.UI.createDefault(map, layers);
      ui.setUnitSystem(H.ui.UnitSystem.IMPERIAL);
      //let zoom = ui.getControl("zoom");
      //console.log("Zoom:", zoom);
      let zoom = new H.ui.ZoomControl({
        fractionalZoom: false,
        zoomSpeed: 1
      })

      ui.removeControl("zoom");
      ui.addControl("zoom", zoom);
      const markers = new H.map.Group();

      var mapEvents = new H.mapevents.MapEvents(map);
      // Add event listeners:
      map.addEventListener('tap', this.onClickMap);
      map.addEventListener('mapviewchange', this.onMoveMap);

      // add the interactive behaviour to the map, disabling double-tap to zoom as well
      var behavior = new H.mapevents.Behavior(mapEvents);
      behavior.disable(H.mapevents.Behavior.DBLTAPZOOM);

      this.map = map;
      this.ui = ui;
      this.markers = markers;
      this.platform = platform;
      this.behavior = behavior;

      this.map.addObject(this.markers);
    }

    window.addEventListener('resize', this.resizeMap);


    this.resizeMap();

    this.addMarker({location_x: this.state.defaultCenter.lat, location_y: this.state.defaultCenter.lng});
  }

  resizeMap = () => {
    this.map.getViewPort().resize();
  }

  addMarker = ({location_x, location_y}) => {
    let icon = this.state['regularSiteMarkerIcon'];
    var coords = { lat: location_x, lng: location_y },
    marker = new H.map.Marker(coords, { icon });
    this.markers.addObject(marker);
    return marker;
  }


  componentWillUnmount = async () => {
    this.ref = null;

    //console.log("Ghostly Overhang Debug", "Calling componentWillUnmount");

    if (this.map) {
      this.map.removeEventListener('tap', this.onClickMap);
      //this.map.removeEventListener('mapviewchange', this.onZoomMap);
      if (this.markers) {
	      //console.log("Ghostly Overhang Debug", "Calling markers.removeAll");
	      this.markers.removeAll();
	    }

      this.map.removeObjects(this.map.getObjects());
    }

    window.removeEventListener('resize', this.resizeMap);
  }



  onClickMap = (evt) => {
   
    console.log("onClickMap")

    //console.log("onClickMap", "Map locked?", locked);
    if (!(evt.target instanceof H.map.Marker)){

      if (evt.currentPointer){

          this.markers.removeAll();

          let location = this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
          //console.log("Calculated location:", location);

          this.addMarker({location_x: location.lat, location_y: location.lng})

          this.setState({location});
          this.props.callbackMarker(location);
      }
    }

  }

  onMoveMap = ({newValue}) => {
    let {lookAt} = newValue;
    if (lookAt && lookAt.position && lookAt.zoom){
      //this.setState({position: lookAt.position, zoom: lookAt.zoom});
      this.props.callbackZoom(lookAt.zoom, lookAt.position);
    }
  }


  render() {
  	return (<div style={{ width: '100%', height:'inherit', maxHeight: 'inherit', minHeight: 'inherit'}} ref={this.ref}></div>);
  }
}
